/* Starting Page CSS */

@media only screen and (max-width: 600px) {

  .date-font{
      font-size: 10px; 
  }
}

#backarrow {
margin-left: -210px;
}

.month-title {
  align-items: left;
  justify-content: left;
  text-align: left;
  margin-left: -35px;
}

* {
  margin: 0px;
  padding: 0px;
}

.date-font{
  font-size: 15px;
  font-family: EB Garamond;
  font-weight: lighter;

}
.num-font{
  font-size: 20px;
  font-family: EB Garamond;

}

.month {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: #88a474
  ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  color: #ffffff;
  font-family: EB Garamond;
  font-weight: 300;
  font-size: 30px;
  z-index: 2;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  color: #ffffff;
  font-family: EB Garamond;
  font-weight: 300;
  font-size: 30px;
  z-index: 2;
}
.journals{
  
  border-style: solid;
  border-width: 5px;
  border-radius: 10px;
  border-color: #323232;
  background-color:#323232
  ;
  margin: 30px;
}

#month-wrapper {
  display: flex;
  flex-direction: row;
}

#arrow-img {
  padding: 10px;
  padding-left: 15px;
}

.fonts{
  color: #ffffff;
  font-family: EB Garamond;
  font-weight: 300;
  font-size: 30px;
  z-index: 2;
}

.dates{
  color: #ffffff;
  font-family: EB Garamond;
  font-weight: 100;
  font-size: 15px;
  z-index: 2;
}

.day{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 0;
}

#today{
  border-radius: 50%;
  background-color: #F4A5AE;
  ;
}
p{
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 15px;
  z-index: 2;
  text-align: left;
}

.space {
  margin-top: 10px;
  margin-bottom: 20px;
}
