.react-calendar{
    font-family: 'EB Garamond';
    border: none;
    background: none;
}

.react-calendar__month-view__weekdays__weekday{
    display: none;
}
.react-calendar__month-view__days__day--weekend{
    color: black;
}
.react-calendar__month-view__days__day--neighboringMonth{
    pointer-events: none;
    visibility: hidden;
}
.react-calendar__tile--now{
    background: #87A073;
    color: white;
}

.react-calendar__tile{
width: 35px;
height: 49.71px;
padding: 0px;
border-radius: 50%;
}
.react-calendar__tile:hover{
background: rgba(0, 0, 0, 0.30);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #87A073;
    color: white;
}





.react-calendar__tile--active {
    background: #F4A5AE;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #F4A5AE;
    color: white;
}