.NewEntry {
    font-family: 'EB Garamond'
}

.entryform {
    margin: 0 22px;
    display: flex;
    flex-direction: column;
    position: relative;
}

textarea[id="journalContent"]::placeholder {
    position: absolute;
    top: 3px;
    left: 3px;
}

input[placeholder="Edit Title"] {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 149px;
    height: 28px;
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

input[placeholder="Edit Title"]::placeholder {
    color: black;
}



.newentryheader {
    margin: 0 18px;
    font-size: 60px;
    font-weight: 400;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: left;
}

.newentrybutton{
    background-color: black;
    color: white;
    margin: 25px auto;
    width: 162px;
    height: 48px;
    border-radius: 9px;

}

#journalContent {
    Width: 346px;
    Height: 429px;  
    background-color: #F4F3F3;
    border: 1px solid black;
    border-radius: 10px;
}

.entryformicons {
    margin: 15px auto;
}