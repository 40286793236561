.landingPage {
  height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: relative;
  background-color: #C4DCA5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.landingPage_title > h1 {
  color: #ffffff;
  font-family: 'EB Garamond';
  font-weight: 600;
  font-size: 48px;
  z-index: 2;
}

.landingPage_title > p {
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  line-height: 31.32px;
  margin-top: 20px;
  z-index: 2;
}

.landingPage_btn {
  margin: 60px 0px 0px 0px;
  z-index: 1000;
}

.register {
  height: 50px;
  width: 180px;
  border-radius: 15px;
  border: 1px;
  background-color: #7D9C65;
  color: #ffffff;
  margin-right: 20px;
  
}

.login {
  height: 50px;
  width: 180px;
  border-radius: 15px;
  background-color: #C4DCA5;
  border-color: #ffffff;
  outline: none;
  border-style: solid;
  color: #ffffff;
  border-width: 1px;
}

.logo {
	bottom: 5%;
	width: 100rem;
	position: absolute;
	z-index: 0;
}

.landingPage_footer {
  background-color: #C4DCA5;
  padding: 5vh 0;
  color: #ffffff;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 15.52px;
	position: absolute;
	bottom: 5px
  
}

.login:hover {
  color: #ffffff;
  background-color: #7D9C65;
  border-color: #7D9C65;
}

.register:hover {
  color: #7D9C65;
  background-color: #ffffff;
}

.mobile-nav {
  display: none;
}

.mobile-default-section {
  display: none;
}

.header {
  display: none;
}


@media (max-width: 390px) {
  .landingPage {
    display: none;
  }
  
  .header {
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  }
  
  .mobile-default-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .starting-page-container {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mobile-title {
    color: #000;
    text-align: center;
    font-family: EB Garamond;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }
  
  .mobile-intro-text {
    color: #000;
    text-align: center;
    font-family: EB Garamond;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin: 30px;
  }
  
  .get-started-link {
    color: #FFF;
    text-align: center;
    font-family: EB Garamond;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    
  }
  
  .get-started-button {
    width: 162px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #000;
    color: #FFF;
    text-align: center;
    font-family: EB Garamond;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
}