.resourcelibrary {
    font-family: 'EB Garamond';
}

.main {
    width: 90vw;
    margin: 5vh auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.mainTitle {
    width:fit-content;
    margin: 5vh auto;
    color: #87A073;
    font-size: 32px;
}

.resourceBox {
    width: 40vw;
}

.resourceBox h1 {
    color: #87A073;
    font-size: 25px;
    width: fit-content;
    margin-left: 5%;
    margin-bottom: 2%;
}

.resource {
    font-size: 20px;
}