@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Comfortaa:wght@300&family=Dosis:wght@300&family=Fira+Code&family=Montserrat:wght@300&family=PT+Serif&display=swap');

h1{
    color: black;
    font-family: EB Garamond;
    font-weight: 400;
    font-size: 42px;
    z-index: 2;
    overflow: auto;

}
body{
    background-color: #f2f2f4;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.first-column {
    width: 70%;
    height:100px;
    padding: 0 20px 0 20px;
}

.second-column {
    width: 30%;
    height:200px;
}


.container{
    display:flex;
}

.customizeCenter{
    width:861px;
    height:921px;
    background-color: #C4DCA5;
    text-align: center;
    overflow: auto;
    margin-bottom: 20px;
    
}

h2{
    color: black;
    font-family: EB Garamond;
    font-weight: 400;
    font-size: 20px;
    z-index: 2;

}

.options{
    color: black;
    font-family: EB Garamond;
    font-weight: 400;
    font-size: 18px;
    z-index: 2;
    margin-left: 15px;
}
p1{
    float:left;
    
}
input{

    border-radius: 5px;
}

#fontbox{
    /*margin-right: 80px;*/
    Width:209px;
    Height:25px;
    margin-left:40px;
}

#textsize{
    width:40px;
    height:25px;
    align-items: left;
    margin-left:40px;
}

.line{
    margin-bottom: 30px;
    display:flex;
    flex-direction: row;
}
#bigimage{
    align-items: center;
}

.right{
    float:right;
}

#bigimage{
    width: 256px;
    height: 62px;
    text-align: center;  
}
img{
    z-index:3
}

#indent{
    float:right;
}
#arrow{
    float:left;
    margin-left: 20px;
}
.buttons{
    display:flex;
    justify-content: center;
    align-items: center;
}

#rect11{
    margin-left:40px;
}

p2{
    font-size: 24px;
    color: black;
    font-family: EB Garamond;
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;

}

.icons{
    margin-left: 10px;
}

#bigimage{
    margin-top: 50px;
}

#backpage{
    width: 250px;
    height: 78px;
}

#nextpage{
    width: 250px;
    height: 78px;
}

.page{
    margin-top: 400px;
    bottom: 5px;
}

#journal_area {
    overflow: auto;
	width:100%;
	overflow:hidden;
	background-color:#FFF;
	color:#222;
	font-family:Courier, monospace;
	font-weight:normal;
	font-size:20px;
	resize:none;
	line-height:55px;

  
    outline:none;
	border:none;
	margin:0px;
	padding:0px;
	font-family:Courier, monospace;

    overflow: hidden; 
    word-wrap: break-word; 
    resize: none; 
    height: 100%;

}

#font-picker{
    z-index: 1000;
    margin-left: 10px;
}