.EB, .EB > span {
    font-family: "EB Garamond", serif !important;
}

.EB > span {
    font-weight: 700;
}

.myJournalMobile {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    margin-left: 22px;
    margin-right:22px;
}

h1 {
    font-size: 32px;
    font-weight: 500;
}

h2 {
    font-size: 28px;
    font-weight: 600;
    color: #323232;
}

.monthAndYear {
    margin-top: 20px;
}

.noJournals {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.noJournals h3 {
    color: #CBC8C8;

}


.monthAndYear, .JournalListBoxHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottomButton {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    height: 50px;
    border-radius: 15px;
    border: 1px solid black;
    font-size: 16px;
    user-select: none;
}

.JournalListBoxHead {
    color: #87A073;
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
}

.JournalListBoxHead:last-child{
    font-weight: 700;
}

.journalEntry {
    display: inline-block;
    position: relative;
    background-color: #323232;
    width: 170px;
    height: 145px;
    border-radius: 7px;
    margin-top: 3px;
    margin-right: 3px;
    color: white;
    transition: background-color 0.8s ease; 
    user-select: none;
}

.journalEntry:active, .bottomButton:active {
    background-color:#87A073;
}

.journalEntry h1 {
    position: absolute;
    font-size: 14px;
    width: fit-content;
    left: 15px;
    bottom: 30px;
}

.journalEntry h2 {
    position: absolute;
    font-size: 12px;
    color: white;
    width: fit-content;
    left: 15px;
    bottom: 15px;
}

.seeMore {
    all: unset;
}