.button {
    transition: background-color ease-in 0.25s;
    background-color: white;
}

.button:hover{
    background: #87A073;
    color: white;
 }

 .circlehov {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color ease-in 0.3s;
 }

 .circlehov:hover {
    background-color: #F8F8F8;
 }